import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { formatNumber } from '../../utils/functions';

function ScreenMarker(props) {
    const { t } = useTranslation();
    const { screen, setScreenOpened, screenOpened, onOpenStreetView } = props;
    const selectedCurrency = useSelector(
        (state) => state.newCampaign.selectedCurrency
    );
    const [isMarkerOpen, setIsMarkerOpen] = useState(false);
    const markerVariants = {
        closed: { opacity: 0, display: 'hidden' },
        open: { opacity: 1, display: 'block' },
    };
    screen.size = `${screen.w}x${screen.h}`;
    
    return (
        <>
            <div className="absolute z-1 w-10 h-10">
                <ReactTooltip
                    effect="solid"
                    className="w-48 p-2"
                    id={String(screen.id)}
                    aria-haspopup="true">
                    {screen.name}
                </ReactTooltip>
                {/* Todo - Improve Marker Color according Screen Type (need to add Screen Types in a future) */}
                <FontAwesomeIcon
                    data-tip
                    data-for={screen.id}
                    onClick={() => setIsMarkerOpen(!isMarkerOpen)}
                    icon={['fas', 'map-marker']}
                    size="2x"
                    className="text-blue-700"
                />
                <motion.div
                    className="absolute z-20 w-48 h-70 overflow-hidden bottom-12 right-4 rounded-md bg-white shadow-md p-2 text-sm cursor-pointer"
                    initial="closed"
                    animate={isMarkerOpen ? 'open' : 'closed'}
                    variants={markerVariants}
                    transition={{ ease: 'easeInOut', duration: 0.15 }}>
                    <div
                        onClick={() => setIsMarkerOpen(false)}
                        className="absolute top-1 right-2 opacity-50 hover:opacity-90 cursor-pointer">
                        <FontAwesomeIcon icon={['fal', 'times']} />
                    </div>

                    <div className="absolute z-10 -bottom-1 right-2 bg-white transform rotate-45 w-3 h-4 shadow-lg"></div>
                    {/* Info */}
                    <div className="flex flex-col mt-4">
                        <div
                            className="font-semibold text-sm"
                            alt={screen.name}>
                            {screen.name}
                        </div>

                        <div className="flex justify-between mt-1">
                            <div className="font-normal text-sm">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.table-screenSize'
                                )}
                                :
                            </div>
                            <div className="font-light text-gray-400 text-right">
                                {screen.size}
                            </div>
                        </div>

                        <div className="flex justify-between mt-1">
                            <div className="font-normal text-sm">
                                {t('common.components.ReportsTable.ecpm')}:
                            </div>
                            <div className="font-light text-gray-400 text-right">
                                {formatNumber(
                                    screen.ecpm,
                                    'properCurrency',
                                    null,
                                    { currency: selectedCurrency.short_name }
                                )}
                            </div>
                        </div>
                        <div className="flex justify-start space-x-2 mt-2">
                            <FontAwesomeIcon
                                onClick={() => onOpenStreetView(screen)}
                                icon={['fad', 'street-view']}
                                className="text-2xl text-blue-300 text-opacity-60 transition-opacity hover:text-opacity-90 duration-300 mr-4"
                            />
                            <FontAwesomeIcon
                                onClick={() => setScreenOpened(screen)}
                                icon={['fad', 'th']}
                                className={`${
                                    screenOpened
                                        ? 'text-blue-800'
                                        : 'text-blue-300'
                                } text-2xl text-opacity-60 transition-opacity hover:text-opacity-90 duration-300 mr-4`}
                            />
                        </div>
                    </div>
                </motion.div>
            </div>
        </>
    );
}

export default ScreenMarker;
