export const COUNTRIES = [
    {
        value: 'TR',
        label: 'Turkey',
    },
    {
        value: 'MX',
        label: 'Mexico',
    },
    {
        value: 'MY',
        label: 'Malaysia',
    },
    {
        value: 'AR',
        label: 'Argentina',
    },
    {
        value: 'UY',
        label: 'Uruguay',
    },
    {
        value: 'PY',
        label: 'Paraguay',
    },
    {
        value: 'ES',
        label: 'Spain',
    },
    {
        value: 'PT',
        label: 'Portugal',
    },
    {
        value: 'MX',
        label: 'Mexico',
    },
    {
        value: 'PE',
        label: 'Peru',
    },
    {
        value: 'CL',
        label: 'Chile',
    },
    {
        value: 'IN',
        label: 'India',
    },
    {
        value: 'PE',
        label: 'Peru',
    },
    {
        value: 'BR',
        label: 'Brazil',
    },
    {
        value: 'ZA',
        label: 'South Africa',
    },
    {
        value: 'CO',
        label: 'Colombia',
    },
];
