import React, { useState, useEffect } from 'react';
import { newCampaignActions, screensActions } from '../../store';
import { useSelector, useDispatch } from 'react-redux';

//checkbox que substitui o select all do react-data-table
const CheckboxAll = () => {
    const {
        selectedScreensLocal,
        showSelectedScreens,
        screensFilteredFromScreensWizard,
    } = useSelector((state) => state.screens);
    const dispatch = useDispatch();
    const [allChecked, setAllChecked] = useState(false);

    let filterBySelected = showSelectedScreens && screensFilteredFromScreensWizard && screensFilteredFromScreensWizard.length
    ? screensFilteredFromScreensWizard.filter((fromWizard) =>
          selectedScreensLocal?.some(
              (fromSelected) => fromSelected.id == fromWizard.id
          )
      )
    : screensFilteredFromScreensWizard;

    useEffect(() => {
        if (screensFilteredFromScreensWizard && screensFilteredFromScreensWizard.length && selectedScreensLocal) {
            let allCheckedByFilter = filterBySelected.every((s) =>
                selectedScreensLocal.some((e) => e.id == s.id)
            );
            setAllChecked(allCheckedByFilter);
        }
    }, [
        screensFilteredFromScreensWizard,
        showSelectedScreens,
        selectedScreensLocal,
    ]);

    const checkAll = () => {
        if (screensFilteredFromScreensWizard.length) {
            if (!allChecked) {
                dispatch(
                    screensActions.setSelectedScreensLocal(
                        screensFilteredFromScreensWizard
                    )
                );
                dispatch(
                    newCampaignActions.setSelectedScreens(
                        screensFilteredFromScreensWizard
                    )
                );

                setAllChecked(!allChecked);
            } else if (allChecked) {
                let newScreens = selectedScreensLocal?.filter(
                    (selected) =>
                        !screensFilteredFromScreensWizard.some(
                            (fromWizard) => fromWizard.id == selected.id
                        )
                ) || [];
                dispatch(screensActions.setSelectedScreensLocal(newScreens));
                dispatch(newCampaignActions.setSelectedScreens(newScreens));
                setAllChecked(!allChecked);
            }
        }
    };

    return selectedScreensLocal && selectedScreensLocal.length > 0 && filterBySelected &&
        filterBySelected.length > 0 &&
        selectedScreensLocal.some((selected) =>
            filterBySelected.some((fromFilter) => fromFilter.id == selected.id)
        ) ? (
        //quando alguns checkboxes estão selecionados, mas não todos
        <div
            className="cursor-pointer flex items-center justify-center text-white text-sm"
            style={{
                background: '#2563eb',
                padding: '3px',
                width: '1rem',
                height: '1rem',
            }}
            onClick={checkAll}
            checked={allChecked}>
            <div className="border border-#2563eb w-3 h-0"></div>
        </div>
    ) : (
        <input
            type="checkbox"
            style={{ height: '16px', width: '16px' }}
            onClick={checkAll}
            checked={allChecked}
        />
    );
};
export default CheckboxAll;