import React, { useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { newCampaignActions } from '../../../../store';
import Hint from '../../../Tools/Hint';
import InputCitiesNeighborhoods from './InputCitiesNeighborhoods/InputCitiesNeighborhoods';
import { COUNTRIES } from '../../../../utils/location/getCountries';
import { REGIONS } from '../../../../utils/location/getRegions';

const Location = ({ location, selectedCurrency, dispatch }) => {
    const { t } = useTranslation();
    const nodes = location.nodes;
    const countries = COUNTRIES;
    const selectedCountries = location.selectedCountries;
    const regions = REGIONS;
    const selectedRegions = location.selectedRegions;
    const cities = location.cities;
    const selectedCities = location.selectedCities;
    const neighborhood = location.neighborhood;
    const selectedNeighborhoods = location.selectedNeighborhoods;
    const countriesName = [];

    for (let i in countries) {
        countriesName.push(countries[i].label);
    }

    const change = (attribute, value) => {
        const isCountry = attribute === 'selectedCountries';
        if (isCountry && value.length === 0) {
            return;
        }

        const newLocation = Object.assign({}, location);
        newLocation[attribute] = value;
        dispatch(newCampaignActions.setLocation(newLocation));
    };

    useEffect(() => {
        updateRegionsAndCities();
    }, [selectedCountries, selectedRegions]);

    const updateRegionsAndCities = () => {
        const optionsRegions = [];
        const optionsCities = [];
        const optionsNeighborhoods = [];
        const areaRegex = /Area/;
        const localityRegex = /Locality/;

        selectedCountries.forEach((country) => {
            const node = nodes.find((node) => node.name === country.value);
            if (country.label === 'Brazil') {
                if (node) {
                    node.nodes?.forEach((area) => {
                        f;
                        if (areaRegex.test(area.type)) {
                            optionsRegions.push({
                                value: area.name,
                                label: area.name,
                            });
                            selectedRegions.forEach((region) => {
                                if (region.value === area.name) {
                                    area.nodes?.forEach((areaOrLocation) => {
                                        if (
                                            areaRegex.test(
                                                areaOrLocation.type
                                            ) &&
                                            areaOrLocation.nodes
                                        ) {
                                            areaOrLocation.nodes?.forEach(
                                                (location) => {
                                                    optionsCities.push({
                                                        value: location.name,
                                                        label: `${areaOrLocation.name}`,
                                                    });
                                                    areaOrLocation.nodes?.forEach(
                                                        (neighbour) => {
                                                            optionsNeighborhoods.push(
                                                                {
                                                                    value: location.name,
                                                                    label: `${location.name}`,
                                                                }
                                                            );
                                                        }
                                                    );
                                                }
                                            );
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            } else {
                if (node) {
                    node.nodes.forEach((area) => {
                        if (areaRegex.test(area.type)) {
                            optionsRegions.push({
                                value: area.name,
                                label: area.name,
                            });
                            selectedRegions.forEach((region) => {
                                if (region.value === area.name) {
                                    area.nodes?.forEach((areaOrLocation) => {
                                        if (
                                            localityRegex.test(
                                                areaOrLocation.type
                                            )
                                        ) {
                                            optionsCities.push({
                                                value: areaOrLocation.name,
                                                label: areaOrLocation.name,
                                            });
                                        } else if (
                                            areaRegex.test(
                                                areaOrLocation.type
                                            ) &&
                                            areaOrLocation.nodes
                                        ) {
                                            areaOrLocation.nodes?.forEach(
                                                (location) => {
                                                    optionsCities.push({
                                                        value: location.name,
                                                        label: `${areaOrLocation.name} - ${location.name}`,
                                                    });
                                                }
                                            );
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        });

        const allCities = [];
        const unrepeatedCities = [];
        const citiesObject = [];
        const allNeighbours = [];
        const unrepeatedNeighbours = [];
        const neighbourObject = [];

        for (let i in optionsCities) {
            allCities.push(optionsCities[i].label);
        }

        for (let i = 0; i < allCities.length; i++) {
            const element = allCities[i];

            if (!unrepeatedCities.includes(allCities[i])) {
                unrepeatedCities.push(element);
            }
        }

        for (let i in unrepeatedCities) {
            citiesObject.push({
                value: unrepeatedCities[i],
                label: unrepeatedCities[i],
            });
        }

        for (let i in optionsNeighborhoods) {
            allNeighbours.push(optionsNeighborhoods[i].label);
        }

        for (let i = 0; i < allNeighbours.length; i++) {
            const element = allNeighbours[i];

            if (!unrepeatedNeighbours.includes(allNeighbours[i])) {
                unrepeatedNeighbours.push(element);
            }
        }

        for (let i in unrepeatedNeighbours) {
            neighbourObject.push({
                value: unrepeatedNeighbours[i],
                label: unrepeatedNeighbours[i],
            });
        }

        const newLocation = Object.assign({}, location);
        newLocation.regions = optionsRegions;
        newLocation.cities = citiesObject;
        newLocation.neighborhood = neighbourObject;
        dispatch(newCampaignActions.setLocation(newLocation));
    };

    useEffect(() => {
        const regionsToDelete = getRegionsToDelete();
        const citiesToDelete = getCitiesToDelete();

        if (regionsToDelete.length > 0 || citiesToDelete.length > 0) {
            const newLocation = Object.assign({}, location);
            newLocation.selectedRegions = selectedRegions.filter(
                (region) =>
                    !regionsToDelete.some(
                        (regionToDelete) =>
                            regionToDelete.value === region.value
                    )
            );
            newLocation.selectedCities = selectedCities.filter(
                (city) =>
                    !citiesToDelete.some(
                        (cityToDelete) => cityToDelete.value === city.value
                    )
            );
            dispatch(newCampaignActions.setLocation(newLocation));
        }
    }, [regions, cities]);

    // Return the regions to have to delete from selectedRegions.
    // when one county is removed from selectedCountry, all regions of that country should be removed
    const getRegionsToDelete = () => {
        const regionsToDelete = [];
        selectedRegions.forEach((selectedRegion) => {
            if (
                !regions.some((region) => region.value === selectedRegion.value)
            ) {
                regionsToDelete.push(selectedRegion);
            }
        });
        return regionsToDelete;
    };
    // Return the cities to have to delete from selectedCities.
    // When one region is removed from selectedRegions, all cities of that region should be removed
    const getCitiesToDelete = () => {
        const citiesToDelete = [];
        selectedCities.forEach((selectedCity) => {
            if (!cities.some((city) => city.value === selectedCity.value)) {
                citiesToDelete.push(selectedCity);
            }
        });

        return citiesToDelete;
    };

    if (
        countriesName[0] === 'Brazil' &&
        selectedCurrency.short_name === 'BRL'
    ) {
        return (
            <div className="module rounded m-1 mb-4">
                <div className="header flex justify-between content-center">
                    <div className="title text-xl font-extralight">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.title'
                        )}
                    </div>
                    <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.info'
                        )}
                    </div>
                </div>
                <div className="body rounded-sm p-1">
                    <label className="block md:w-3/6 -mb-2">
                        <span className="block text-sm font-medium text-gray-700">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.location.countries-label'
                            )}
                        </span>
                        <Select
                            className=""
                            options={countries}
                            value={selectedCountries}
                            onChange={(selectedCountries) =>
                                change('selectedCountries', selectedCountries)
                            }
                            isMulti={true}
                            isDisabled={true}
                            placeholder={t(
                                'sections.campaigns.new-campaign.wizard-basics.location.countries-placeholder'
                            )}
                        />
                        <Hint
                            className="relative -top-8 ml-2 left-full"
                            title={t(
                                'sections.campaigns.new-campaign.wizard-briefBar.location.country'
                            )}
                            text={
                                selectedCountries.length !== 0
                                    ? t(
                                          'sections.campaigns.new-campaign.wizard-basics.location.currency-label'
                                      ) + `${selectedCurrency.short_name}`
                                    : ''
                            }
                        />
                    </label>
                    {selectedCountries.length == 0 ? null : (
                        <label className="block md:w-3/6 mb-4">
                            <span className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.regions-label'
                                )}
                            </span>
                            <Select
                                options={regions}
                                value={selectedRegions}
                                onChange={(selectedRegions) =>
                                    change('selectedRegions', selectedRegions)
                                }
                                isMulti={true}
                                isSearchable={true}
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.regions-placeholder'
                                )}
                            />
                        </label>
                    )}
                    {selectedRegions.length == 0 ? null : (
                        <label className="block md:w-3/6 mb-4">
                            <span className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.cities-inbrasil-label'
                                )}
                            </span>
                            <Select
                                options={cities}
                                value={selectedCities}
                                onChange={(selectedCities) =>
                                    change('selectedCities', selectedCities)
                                }
                                isMulti={true}
                                isSearchable={true}
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.cities-placeholder'
                                )}
                            />
                        </label>
                    )}
                    {selectedCities.length == 0 ? null : (
                        <label className="block md:w-3/6 mb-4">
                            <span className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.neighbourhood-label'
                                )}
                            </span>
                            <Select
                                options={neighborhood}
                                value={selectedNeighborhoods}
                                onChange={(selectedNeighborhoods) =>
                                    change(
                                        'selectedNeighborhoods',
                                        selectedNeighborhoods
                                    )
                                }
                                isMulti={true}
                                isSearchable={true}
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.neighbourhood-placeholder'
                                )}
                            />
                        </label>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className="module rounded m-1 mb-4">
                <div className="header flex justify-between content-center">
                    <div className="title text-xl font-extralight">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.title'
                        )}
                    </div>
                    <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.location.info'
                        )}
                    </div>
                </div>
                <div className="body rounded-sm p-1">
                    <label className="block md:w-3/6 -mb-2">
                        <span className="block text-sm font-medium text-gray-700">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.location.countries-label'
                            )}
                        </span>
                        <Select
                            className=""
                            options={countries}
                            defaultValue={selectedCountries}
                            onChange={(selectedCountries) =>
                                change('selectedCountries', selectedCountries)
                            }
                            isMulti={true}
                            placeholder={t(
                                'sections.campaigns.new-campaign.wizard-basics.location.countries-placeholder'
                            )}
                        />
                        <Hint
                            className="relative -top-8 ml-2 left-full"
                            title={t(
                                'sections.campaigns.new-campaign.wizard-briefBar.location.country'
                            )}
                            text={
                                selectedCountries.length !== 0
                                    ? t(
                                          'sections.campaigns.new-campaign.wizard-basics.location.currency-label'
                                      ) + `${selectedCurrency.short_name}`
                                    : ''
                            }
                        />
                    </label>
                    {selectedCountries.length == 0 ? null : (
                        <label className="block md:w-3/6 mb-4">
                            <span className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.regions-label'
                                )}
                            </span>
                            <Select
                                options={regions}
                                value={selectedRegions}
                                onChange={(selectedRegions) =>
                                    change('selectedRegions', selectedRegions)
                                }
                                isMulti={true}
                                isSearchable={true}
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.regions-placeholder'
                                )}
                            />
                        </label>
                    )}
                    {selectedRegions.length == 0 ||
                    selectedCountries.length == 0 ? null : (
                        <label className="block md:w-3/6 mb-4">
                            <span className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.cities-label'
                                )}
                            </span>
                            <InputCitiesNeighborhoods id="cities-neighborhoods-search" />
                            {/* <Select
                                options={cities}
                                value={selectedCities}
                                onChange={(selectedCities) =>
                                    change('selectedCities', selectedCities)
                                }
                                isMulti={true}
                                isSearchable={true}
                                placeholder={t(
                                    'sections.campaigns.new-campaign.wizard-basics.location.cities-placeholder'
                                )}
                            /> */}
                        </label>
                    )}
                </div>
            </div>
        );
    }
};

export default Location;
