import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import DataTable from 'react-data-table-component';
import ScreenPublisherList from './Filters/ScreenPublisherList/ScreenPublisherList';
import ScreenTypeList from './Filters/ScreenTypesList/ScreenTypesList';

import { newCampaignActions, screensActions } from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowSelectedScreens from './Filters/ShowSelectedScreens/ShowSelectedScreens';
import Search from './Filters/Search/Search';

import {
    cpmFromPrice,
    formatNumber,
    formatScreenLocation,
    getCpmMax,
    screenLat,
    screenLon,
} from '../../../utils/functions';
import LinkButton from '../../Buttons/LinkButton';
import PropTypes from 'prop-types';
import SwitcherButton from '../../Buttons/CommonSwitcher';
import { useAuth } from '../../../containers/UseAuth';
import BarChart from './BarChart/BarChart';
import Hint from '../../Tools/Hint';
import ReactTooltip from 'react-tooltip';
import ImportScreens from '../../ImportScreens/ImportScreens';
import { useSelector } from 'react-redux';
import Checkbox from '../../Checkboxes/CustomCheckBox';
import CheckboxAll from '../../Checkboxes/checkboxAll';

import CopyText from '../../CopyText/CopyText';
import usePromise from '../../../hooks/utils/usePromise';
import ScreenTierList from './Filters/ScreenTierList/ScreenTierList';
import { MaxCpmInput } from './Filters/MaxCpmInput/MaxCpmInput';
import AudienceProvider from '../WizardBasics/AudienceProvider/AudienceProvider';
import axiosOriginal from 'axios';
import {
    screensMapedCpmForCampaign,
    screensWithSpotCost,
} from '../../../../src/constants/endpoints';
import { useService } from '../../../containers/UseService';

function WizardScreens({
    newCampaign,
    isResizeMapOn,
    filteredScreensLocal,
    dispatch,
    campaignTimezone,
    screensWithCpm,
    loadingDataTable,
    fetchingWithCpm,
    setLoadingDataTable,
    userMargin,
    isFetchingData,
    setSelectedScreensLocal,
    selectedScreensLocal,
    userId,
    service,
    screenOpened,
    setScreenOpened,
    setFilteredScreensLocal,
}) {
    const { t } = useTranslation();
    const budget = newCampaign.budget;
    const selectedCurrency = newCampaign?.selectedCurrency?.symbol;
    const selectedCurrencyShortName = newCampaign?.selectedCurrency?.short_name;
    const currentWizard = newCampaign.currentWizard;
    const cpm = budget.cpm;
    const isCpmOptimized = budget.cpmOptimizer;
    // const { getScreens } = useService();

    // const { screens } = useSelector((state) => state.screens);
    const {
        isFilterByAudiences,
        selectedPublishers,
        showSelectedScreens,
        selectedScreenTypes,
        screensFilteredFromScreensWizard,
        tierList,
        selectedTiers,
        maxCpm,
    } = useSelector((state) => state.screens);

    const selectedCurrencyScreen = newCampaign.selectedCurrency;
    const selectedCurrencyName = selectedCurrencyScreen?.short_name;
    const provider = newCampaign.demographics.id
        ? newCampaign.demographics?.id?.toLowerCase()
        : 'onemata';

    const [loadingList, setLoadingList] = useState(false);
    const [screenList, setScreenList] = useState();
    useEffect(() => {
        let screensResponse;
        const fetchScreens = async () => {
            setLoadingList(false);
            screensResponse = await axiosOriginal
                .get(
                    // `http://localhost:3001/stats/screens/mapedforcampaign?provider=${provider}&userId=${userId}&localCurrency=${selectedCurrencyName}`
                    screensMapedCpmForCampaign +
                        `?provider=${provider}&userId=${userId}&localCurrency=${selectedCurrencyName}`
                )
                .finally(setLoadingList(false));

            setScreenList(screensResponse.data.message);
            setFilteredScreensLocal(screensResponse.data.message);
        };
        fetchScreens();
    }, [userId]);

    useEffect(() => {
        screensActions.setScreensFilteredFromScreensWizard(screenList);
    }, [screenList]);

    const [hideColumn, setHideColumn] = useState(false);
    const initialPreselected = (row) =>
        selectedScreensLocal.some(
            (selectedScreen) => selectedScreen.id === row.id
        );
    const [selectedFunction, setSelectedFunction] = useState(
        () => initialPreselected
    );
    const auth = useAuth();
    const isSupervising = auth.isSupervising();
    const isAdmin = auth.isAdmin();

    const audienceProvider = newCampaign?.demographics?.id;

    const filterByPublisher = (screens) => {
        return selectedPublishers.length === 0
            ? screens
            : screens.filter((screen) =>
                  selectedPublishers.some(
                      (publisher) =>
                          publisher.name.toLowerCase() ===
                          screen.publisher.name.toLowerCase()
                  )
              );
    };

    const filterByTier = (screens) => {
        return selectedTiers.length === 0
            ? screens
            : screens.filter((screen) =>
                  selectedTiers.some((tier) => tier.ids.includes(screen.tierId))
              );
    };

    const filterByMaxCpm = (screens) => {
        return !maxCpm
            ? screens
            : screens.filter((screen) => screen.ecpm <= maxCpm);
    };

    const filterByScreenType = (screens) => {
        return selectedScreenTypes.length === 0
            ? screens
            : screens.filter((screen) =>
                  selectedScreenTypes.some(
                      (screenType) => screenType.name === screen.screenType
                  )
              );
    };

    const filterByScreenAudience = (screens) => {
        return !isFilterByAudiences
            ? screens
            : screens.filter((screen) => screen.hasAudience);
    };

    const filterByCurrency = (screens) => {
        // return screens.filter((screen) =>
        //     screen.deals.prices?.some(
        //         (item) => item.currency === selectedCurrencyShortName
        //     )
        // );
    };

    const filterWithPrice = (screens) => {
        // return screens.filter((screen) =>
        //     screen.deals.prices?.some((item) => item.price > 0)
        // );
    };

    // const filterTestScreens = (screens) => {
    //     if (!auth.isAdmin()) {
    //         return screens.filter(
    //             (screen) => !screen.label.toLowerCase().includes('test')
    //         );
    //     } else {
    //         return screens;
    //     }
    // };

    const updateSelectedScreens = (selectedScreens) => {
        setSelectedScreensLocal(selectedScreens);
        setSelectedFunction(initialPreselected);
        if (!showSelectedScreens) {
            dispatch(screensActions.setShowSelectedScreens());
        }
        dispatch(newCampaignActions.setSelectedScreens(selectedScreens));
    };

    const handleScreenDetails = (screen) => {
        setScreenOpened(screen);
    };

    const filterFuctions = [
        filterByPublisher,
        filterByScreenType,
        filterByScreenAudience,
        // filterByCurrency,
        // filterTestScreens,
        // filterWithPrice,
        filterByTier,
        filterByMaxCpm,
    ];

    const [flag, setFlag] = useState(false);

    // filter by screen type and publishers the screens filtered from location wizard
    const applyFilters = () => {
        setFlag(true);
        let filteredScreens = JSON.parse(JSON.stringify(filteredScreensLocal));

        filterFuctions.forEach((functionFilter) => {
            filteredScreens = functionFilter(filteredScreens);
        });

        if (!filteredScreens) {
            // return screens.screens;
            return screenList;
        } else {
            return filteredScreens;
        }
    };

    useEffect(() => {
        let screensFilteredByAll = applyFilters();
        dispatch(
            screensActions.setScreensFilteredFromScreensWizard(
                screensFilteredByAll
            )
        );
    }, [
        filteredScreensLocal,
        screensWithCpm,
        selectedScreenTypes,
        selectedPublishers,
        isFilterByAudiences,
        selectedTiers,
        screenList,
    ]);

    const handleSelectedRowsChange = ({ selectedRows }) => {
        setSelectedScreensLocal(selectedRows);
        // setSelectedFunction(initialPreselected);
    };

    // is only updated when the screens component is exited
    useEffect(() => {
        return () => {
            // cpm max of screens selected
            if (budget.cpmOptimizer && auth.session?.user?.cpm_mode === 0) {
                const newBudget = Object.assign({}, budget);
                newBudget.cpm = getCpmMax(selectedScreensLocal);
                dispatch(newCampaignActions.setBudget(newBudget));
            }
        };
    }, [currentWizard, screensWithCpm]);

    const styleLabel = {
        color: '#bdbdbd',
        fontSize: 10,
        marginTop: 0.5,
    };

    const titleWarning = (row, toShowWarning) => {
        if (!isCpmOptimized && row.deals.prices[0].price > cpm) {
            return t(
                'sections.campaigns.new-campaign.wizard-screens.screens.cpm-low'
            );
        } else {
            return toShowWarning ? false : row.name;
        }
    };

    const calcularCustoPorPonto = (row) => {
        if (row.spotCost) return row.spotCost;
        const price = row.deals?.prices?.[0]?.price || 0;
        const costPerSpot = price / 1000;
        return costPerSpot / (1 - userMargin);
    };

    const formatarCustoPorPonto = (row) => {
        const costPerSpot = calcularCustoPorPonto(row);
        return formatNumber(costPerSpot, 'properCurrency', null, {
            currency: selectedCurrencyShortName,
        });
    };

    const columns = useMemo(
        () => [
            {
                name: (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '15px',
                            left: '8px',
                        }}>
                        <CheckboxAll />
                    </div>
                ),
                selector: (row) => row.name,
                sortable: false,
                cell: (row) => <Checkbox row={row} />,
                allowOverflow: true,
                width: '1ch',
            },
            {
                name: (
                    <button style={{ marginLeft: '10px' }}>
                        {t(
                            'sections.campaigns.new-campaign.wizard-screens.screens.table-screenName'
                        )}
                    </button>
                ),
                selector: (row) => row.name,
                sortable: true,
                cell: (row) => (
                    <div
                        className="w-full truncate"
                        title={titleWarning(row, false)}>
                        {titleWarning(row, true) && (
                            <div
                                className="absolute "
                                style={{ right: '-2%', bottom: '29%' }}>
                                <FontAwesomeIcon
                                    icon={['fas', 'exclamation-triangle']}
                                    fixedWidth
                                    className="text-red-300"
                                />
                            </div>
                        )}{' '}
                        {screensWithCpm.length &&
                        !screensWithCpm.some((s) => s.screen_id === row.id) ? (
                            <div className="inline-flex">
                                <span
                                    className={`inline-block bg-gray-200 p-1 mr-1 mb-1 rounded-sm`}
                                    style={{
                                        fontSize: '.8em',
                                        padding: '1px .4em 1px .4em',
                                    }}>
                                    <CopyText text={row?.tagId} />
                                </span>
                                {(row.weeklyRequests || 0) < 2240 && (
                                    <span
                                        data-tip={t(
                                            'common.tooltips.screens.without-requests'
                                        )}
                                        style={{
                                            cursor: 'default',
                                            position: 'relative',
                                        }}
                                        data-for="withOutRequest">
                                        <div className="rounded-full w-3 h-3 group mr-[-18px] flex items-center justify-center bg-red-500"></div>

                                        <ReactTooltip
                                            id="withOutRequest"
                                            place="top"
                                            effect="solid"
                                        />
                                    </span>
                                )}
                                {row.adType === 'VAST' && (
                                    <span
                                        data-tip={t(
                                            'common.tooltips.screens.vast'
                                        )}
                                        data-for="notValid"
                                        className="inline-block bg-gray-200 p-1 mr-1 mb-1 rounded-sm text-blue-400"
                                        style={{
                                            fontSize: '.8em',
                                            padding: '1px .4em 1px .4em',
                                        }}>
                                        <strong>VAST</strong>
                                    </span>
                                )}
                                <ReactTooltip
                                    id="notValid"
                                    place="top"
                                    effect="solid"
                                />
                                <Hint
                                    title={t(
                                        'sections.campaigns.new-campaign.wizard-basics.timing.average-impacts-measurement-title'
                                    )}
                                    type="warning"
                                    text={t(
                                        'sections.campaigns.new-campaign.wizard-screens.screens.screens-without-impacts-label'
                                    )}
                                    icon={'exclamation-triangle'}
                                    className="ml-1"
                                    dataFor={'no-impacts'}
                                />
                            </div>
                        ) : (
                            <span className="relative">
                                <span
                                    className={`inline-block bg-gray-200 p-1 mr-1 mb-1 rounded-sm`}
                                    style={{
                                        fontSize: '.8em',
                                        padding: '1px .4em 1px .4em',
                                    }}>
                                    <CopyText text={row?.tagId} />
                                </span>
                                {(row.weeklyRequests || 0) < 2240 && (
                                    <span
                                        data-tip={t(
                                            'common.tooltips.screens.without-requests'
                                        )}
                                        style={{
                                            cursor: 'default',
                                            position: 'relative',
                                        }}
                                        data-for="withOutRequest">
                                        <div
                                            className="rounded-full w-3 h-3 group mr-[-18px] bg-red-500"
                                            style={{
                                                borderRadius: '50%',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}></div>
                                        <ReactTooltip
                                            id="withOutRequest"
                                            place="top"
                                            effect="solid"
                                        />
                                    </span>
                                )}
                                {row.adType === 'VAST' && (
                                    <span
                                        data-tip={t(
                                            'common.tooltips.screens.vast'
                                        )}
                                        data-for="notValid"
                                        className="inline-block bg-gray-200 p-1 mr-1 mb-1 rounded-sm text-blue-400"
                                        style={{
                                            fontSize: '.8em',
                                            padding: '1px .4em 1px .4em',
                                        }}>
                                        <strong>VAST</strong>
                                    </span>
                                )}
                                <ReactTooltip
                                    id="notValid"
                                    place="top"
                                    effect="solid"
                                />
                            </span>
                        )}
                        <br />
                        {row.name}
                    </div>
                ),
                width: '40ch',
            },
            {
                name: '',
                selector: (row) => <FontAwesomeIcon icon={faInfo} />,
                cell: (row) => (
                    <FontAwesomeIcon
                        icon={['fal', 'info-circle']}
                        fixedWidth
                        className="text-blue-500 cursor-pointer text-lg"
                        style={{ marginBottom: '-5px', marginLeft: '-3px' }}
                        onClick={() => handleScreenDetails(row)}
                    />
                ),
                sortable: false,
                width: '4ch', // Ajusta el ancho según sea necesario
            },
            {
                name: (
                    <div
                        style={{
                            marginLeft: '20px',
                        }}>
                        {' '}
                        {t(
                            'sections.management.section.pricing.tierList.columns.screenType'
                        )}
                    </div>
                ),
                selector: (row) => {
                    return (
                        <div
                            style={{
                                marginRight: '0px',
                            }}>
                            {' '}
                            {row.tierId ? tierList[row.tierId] : row.tierId}
                        </div>
                    );
                },
                sortable: true,
                omit: hideColumn,
                width: '23ch',
            },
            {
                name: (
                    <div id="ecpm-name" className="flex">
                        <Hint
                            text={t(
                                'sections.campaigns.new-campaign.wizard-screens.screens.ecpm-hint-description'
                            )}
                            type="info"
                            className="mr-1 mt-1"
                            dataFor={'ecpm-name'}
                        />
                        <span className="flex mt-1">
                            <b>{t('common.words.ecpm')}</b>{' '}
                            <b style={styleLabel}>
                                ({selectedCurrencyShortName})
                            </b>
                        </span>
                    </div>
                ),
                selector: (row) => {
                    return row.ecpm || cpmFromPrice(row.deals, userMargin);
                },
                sortable: true,
                right: true,
                omit: hideColumn,
                cell: (row) => {
                    return (
                        <div
                            style={{
                                marginRight: '45px',
                            }}>
                            {formatNumber(
                                row.ecpm
                                    ? row.ecpm
                                    : cpmFromPrice(row.deals, userMargin),
                                'properCurrency',
                                null,
                                {
                                    currency: selectedCurrencyShortName,
                                }
                                // Formatea el número solo para la visualización
                            )}{' '}
                        </div>
                    );
                },
                width: '21ch',
            },
            {
                name: (
                    <div
                        id="impacts-name"
                        className="flex"
                        style={{ marginRight: '40px' }}>
                        <Hint
                            text={t(
                                'sections.campaigns.new-campaign.wizard-screens.screens.impacts-hint-description'
                            )}
                            type="info"
                            className="mr-1 mt-1"
                            dataFor={'impacts-name'}
                        />
                        <span className="mt-1">
                            <b>
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.table-screenWeeklyImpacts'
                                )}
                            </b>{' '}
                        </span>
                    </div>
                ),
                selector: (row) => row.weeklyImpacts || 0,
                cell: (row) => (
                    <div style={{ marginRight: '10px' }}>
                        {formatNumber(row.weeklyImpacts || 0, 'withcommas')}
                    </div>
                ),
                sortable: true,
                omit: hideColumn,
                right: true,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-screens.screens.table-screenSize'
                ),
                selector: (row) => {
                    const size = `${row.w}x${row.h}`;
                    return size;
                },
                sortable: true,
                omit: hideColumn,
                right: true,
                width: '15ch',
            },
            {
                name: (
                    <span>
                        <b>{t('common.components.ReportsTable.spot-cost')}</b>{' '}
                        <b style={styleLabel}>({selectedCurrencyShortName})</b>
                    </span>
                ),
                selector: (row) => calcularCustoPorPonto(row),
                cell: (row) => formatarCustoPorPonto(row),
                sortable: true,
                right: true,
                omit: !isSupervising && !isAdmin,
            },
            {
                name: t(
                    'sections.campaigns.new-campaign.wizard-screens.screens.table-screenPublisher'
                ),
                selector: (row) => {
                    return (namer =
                        (row.publisher && row.publisher.name) || '');
                },
                cell: (row) => {
                    const namer = (row.publisher && row.publisher.name) || '';
                    return <div title={namer}>{namer}</div>;
                },
                sortable: true,
                width: '19ch',
            },
        ],
        [hideColumn, selectedCurrency, isCpmOptimized, screensWithCpm]
    );

    const conditionalRowStyles = [
        {
            when: (row) => !isCpmOptimized && row.deals.prices[0].price > cpm,
            style: {
                opacity: 0.55,
            },
        },
        {
            when: (row) =>
                selectedScreensLocal.length &&
                selectedScreensLocal.some((screen) => screen?.id === row?.id),
            style: {
                backgroundColor: 'rgba(59, 130, 246, 0.17)',
            },
        },

        {
            when: (row) => screenOpened && screenOpened.id == row.id, // supongamos que tienes una propiedad isClickable en tus datos
            style: {
                backgroundColor: 'rgba(176, 215, 241, 0.15)',
            },
        },
    ];

    const paginationComponentOptions = {
        rangeSeparatorText: t('common.words.of'),
        rowsPerPageText: t(
            'common.components.react-data-table.screens-per-page'
        ),
        selectAllRowsItemText: t(
            'common.components.react-data-table.select-all-screens'
        ),
    };

    const noDataComponent = t('common.components.react-data-table.no-screens');
    return (
        <>
            <div
                className={`screens mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-300 ${
                    isResizeMapOn
                        ? 'transform scale-75 -translate-y-20 -mr-32 -ml-16'
                        : ''
                }`}>
                <div className="flex justify-start">
                    <div
                        className={`mt-2 border-r  ${
                            isResizeMapOn ? 'hidden' : 'block'
                        }`}>
                        <div className="title font-normal">
                            {t(
                                'sections.campaigns.new-campaign.wizard-screens.filters.filters-label'
                            )}
                        </div>
                        <div className="divide-y flex flex-col">
                            <div className="mt-4">
                                <div className="title font-light">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-screens.filters.audienceProviders-label'
                                    )}
                                </div>
                                <AudienceProvider />
                            </div>
                            <ShowSelectedScreens
                                showSelectedScreens={showSelectedScreens}
                                setShowSelectedScreens={() =>
                                    dispatch(
                                        screensActions.setShowSelectedScreens()
                                    )
                                }
                            />
                            {!filteredScreensLocal[0]?.tagId.startsWith(
                                'bbt'
                            ) && (
                                <div className="py-2 w-40">
                                    <div className="title font-light">
                                        {t(
                                            'sections.campaigns.new-campaign.wizard-screens.filters.show-with-audiences'
                                        )}
                                    </div>
                                    {auth?.session?.user?.cpm_mode == 0 && (
                                        <SwitcherButton
                                            onToggle={() =>
                                                dispatch(
                                                    screensActions.setIsFilterByAudiences()
                                                )
                                            }
                                            labelOn={t(
                                                'sections.campaigns.new-campaign.wizard-screens.filters.audience-label'
                                            )}
                                            labelOff={t(
                                                'sections.campaigns.new-campaign.wizard-screens.filters.showSelected-labelOff'
                                            )}
                                            status={isFilterByAudiences}
                                            revertLabel={true}
                                        />
                                    )}
                                </div>
                            )}
                            {auth.session.user.cpm_mode === 1 && (
                                <MaxCpmInput
                                    dispatch={dispatch}
                                    newCampaign={newCampaign}
                                    maxCpm={maxCpm}
                                    t={t}
                                    loadingDataTable={loadingDataTable}
                                    setLoadingDataTable={(x) =>
                                        setLoadingDataTable(x)
                                    }
                                    applyFilters={applyFilters}
                                />
                            )}
                            {/* When we can filter screen by audience, put this filter
                        <AudienceData addCriteriaToChain={addCriteriaToChain} updateFilters={toggleUpdateFilters}/> */}
                            <ScreenTypeList
                                screens={filteredScreensLocal}
                                filteredScreensLocal={filteredScreensLocal}
                                screensFilteredFromScreensWizard={
                                    screensFilteredFromScreensWizard
                                }
                            />
                            <ScreenPublisherList
                                filteredScreensLocal={filteredScreensLocal}
                                screensFilteredFromScreensWizard={
                                    screensFilteredFromScreensWizard
                                }
                            />
                            <ScreenTierList
                                filteredScreensLocal={filteredScreensLocal}
                                screensFilteredFromScreensWizard={
                                    screensFilteredFromScreensWizard
                                }
                            />
                        </div>
                    </div>
                    <div className="module md:w-5/6 rounded m-1 mb-4 pb-10">
                        <div className="header flex justify-between content-center">
                            <div className="title text-xl font-extralight">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-screens.screens.screens-title'
                                )}
                                <ImportScreens
                                    availableScreens={
                                        screensFilteredFromScreensWizard
                                    }
                                    selectedScreensLocal={selectedScreensLocal}
                                    updateSelectedScreens={
                                        updateSelectedScreens
                                    }
                                    dispatch={dispatch}
                                />
                            </div>
                            <div className="w-3/6">
                                <Search applyFilters={applyFilters} />
                                <div
                                    className={`${
                                        selectedScreensLocal.length === 0
                                            ? ''
                                            : 'invisible'
                                    } brief font-light text-xs text-gray-400 mt-1.5 md:mr-1 text-right`}>
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-screens.screens.screens-info'
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${
                                newCampaign.demographics.id ? 'mb-2 ' : 'hidden'
                            }`}>
                            <span className="text-xs font-bold">
                                {t(
                                    `sections.campaigns.new-campaign.wizard-audiences.forecast.${newCampaign.demographics.id}-info`
                                )}
                            </span>
                        </div>
                        <div className="p-1 w-full overflow-x-auto">
                            {/* Documentación: https://www.npmjs.com/package/react-data-table-component */}
                            <div className="p-1 w-full">
                                <DataTable
                                    responsive={true}
                                    columns={columns}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={'60vh'}
                                    subHeader={false}
                                    selectableRows={false}
                                    pagination
                                    selectableRowsNoSelectAll={false}
                                    paginationRowsPerPageOptions={[
                                        100, 200, 300,
                                    ]}
                                    paginationIconLastPage={false}
                                    paginationIconFirstPage={false}
                                    progressPending={
                                        loadingDataTable ||
                                        fetchingWithCpm ||
                                        isFetchingData ||
                                        loadingList
                                    }
                                    paginationPerPage={100}
                                    selectableRowsHighlight={false}
                                    scrollCollapse
                                    data={
                                        showSelectedScreens
                                            ? screensFilteredFromScreensWizard.filter(
                                                  (e) =>
                                                      selectedScreensLocal.some(
                                                          (a) => a.id == e.id
                                                      )
                                              )
                                            : screensFilteredFromScreensWizard
                                        // screenList
                                        // screens
                                    }
                                    keyField={'id'}
                                    paginationComponentOptions={
                                        paginationComponentOptions
                                    }
                                    noDataComponent={noDataComponent}
                                    conditionalRowStyles={conditionalRowStyles}
                                    progressComponent={
                                        <>
                                            <FontAwesomeIcon
                                                icon={['fas', 'circle-notch']}
                                                className="text-blue-400 animate-spin mx-2"
                                            />
                                            {t(
                                                'sections.campaigns.new-campaign.wizard-screens.screens.loading-screens'
                                            )}
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* TO DO
            <DistributionByScreens/> */}
            </div>
        </>
    );
}

WizardScreens.proptyTypes = {
    isActive: PropTypes.bool.isRequired,
    setSelectedScreensLocal: PropTypes.func,
    selectedScreensLocal: PropTypes.array,
    screens: PropTypes.array,
    isResizeMapOn: PropTypes.bool,
    newCampaign: PropTypes.object,
    selectedScreenTypes: PropTypes.array,
    selectedPublishers: PropTypes.array,
    dispatch: PropTypes.func,
};

export default WizardScreens;
