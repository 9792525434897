import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newCampaignActions, notificationsActions } from '../../../../../store';
import AsyncSelect from 'react-select/async';
import { getRandomHex, hexaToRgb } from '../../../../../utils/functions';
const colorsArr = hexaToRgb(getRandomHex(15));
let debounceLoadOptions;

const InputCitiesNeighborhoods = (props) => {
    const { id } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useSelector((state) => state.newCampaign.location);
    const placesService = new google.maps.places.PlacesService(
        document.createElement('div')
    );
    const autocompleteService = new google.maps.places.AutocompleteService();

    const notExists = (search) => {
        const cities = location?.cities || [];
        const neighborhoods = location?.neighborhood || [];

        const existsInCities = cities.some(
            (city) => city.value === search || city.label === search
        );
        const existsInNeighborhoods = neighborhoods.some(
            (neigh) => neigh.value === search || neigh.label === search
        );

        return !(existsInCities || existsInNeighborhoods);
    };

    const onSelectPrediction = async (prediction) => {
        if (!prediction?.description || !notExists(prediction.description))
            return;

        const search = prediction.description;
        const request = { query: search };

        try {
            const results = await new Promise((resolve, reject) => {
                placesService.textSearch(request, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        resolve(results);
                    } else {
                        reject(`Status Error: ${status}`);
                    }
                });
            });

            if (!results.length) return;

            const [firstResult] = results;
            const { formatted_address, types } = firstResult;

            const isNeighborhood =
                types.includes('sublocality') && types.includes('political');

            const locationData = {
                value: formatted_address,
                label: formatted_address,
            };

            const newLocation = { ...location };

            if (isNeighborhood) {
                newLocation.selectedNeighborhoods = [locationData];
                newLocation.neighborhood = [locationData];
            } else {
                newLocation.selectedCities = [locationData];
                newLocation.cities = [locationData];
            }

            dispatch(newCampaignActions.setLocation(newLocation));
        } catch (error) {
            dispatch(
                notificationsActions.setNotification({
                    type: 'error',
                    title: 'Erro',
                    message: error,
                })
            );
        }
    };

    const loadOptions = (inputValue, callback) => {
        if (!inputValue.trim()) {
            callback([]);
            return;
        }

        if (debounceLoadOptions) {
            clearTimeout(debounceLoadOptions);
        }

        debounceLoadOptions = setTimeout(() => {
            autocompleteService.getQueryPredictions(
                {
                    input: inputValue,
                    types: ['(cities)'],
                    componentRestrictions: { country: 'BR' },
                },
                (res, status) => {
                    if (
                        status !== google.maps.places.PlacesServiceStatus.OK ||
                        !res
                    ) {
                        console.error('Erro ao buscar previsões:', status);
                        callback([]);
                        return;
                    }

                    const filteredPredictions = res.filter(
                        (prediction) =>
                            !prediction.types.some((tipo) =>
                                [
                                    // 'sublocality',
                                    'route',
                                    'point_of_interest',
                                    'establishment',
                                ].includes(tipo)
                            )
                    );

                    const predictions = filteredPredictions.map(
                        (prediction) => ({
                            ...prediction,
                            label: prediction.description,
                            value: prediction.description,
                        })
                    );

                    callback(predictions);
                }
            );
        }, 300);
    };

    return (
        <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            onChange={onSelectPrediction}
            placeholder={t(
                'sections.campaigns.new-campaign.wizard-basics.location.cities-placeholder'
            )}
            noOptionsMessage={() =>
                t(
                    'sections.campaigns.new-campaign.wizard-basics.location.noOptions-placeholder'
                )
            }
            value={
                location.cities.length > 0
                    ? location.cities
                    : location.neighborhood
            }
            inputId={id}
        />
    );
};

export default InputCitiesNeighborhoods;
